import { Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"



const data02 = [
    { name: 'Surveys opened', value: 3000 },
    { name: 'surveys submitted', value: 2000 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const SurveyReport = () => {
    return (
        <div className="main_inner_section">
            <div className="row border-bottom p-3">
                <h5 className="heading-title m-0">Survey Reports</h5>
            </div>
            <div className="p-3">
                hello
                <ResponsiveContainer width={"100%"} height={500}>
                    <PieChart width={400} height={400}>
                        <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default SurveyReport