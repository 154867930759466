import { FC, Suspense, useEffect, useState } from "react"
import { Outlet, useSearchParams } from "react-router-dom"
import { useUser } from "../context/userProvider";
import PageLoader from "../components/pageLoader";
import { api_routes } from "../helper/api_routes";
import { useAxios } from "../context/axiosProvider";
import { MyProfileType, UserType } from "../helper/types";
import { isAxiosError } from "axios";
import InsufficientPermissionPage from "../pages/insufficientPermissionPage";
import { setCookie } from "react-use-cookie";

/*
  * Layout that checks auth token is valid or not. if valid then persist user data or token else invalidate existing auth token
*/
const PersistUserLoginLayout: FC = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [isForbidden, setIsForbidden] = useState<boolean>(false)
    const { setUser, setParentUser, setParentUserOrg, removeUser, userCookieData, parentUserCookieData, parentUserOrgCookieData } = useUser();
    const { axios } = useAxios();
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")

    useEffect(() => {

        let isMounted = true;
        const checkUserAuthenticated = async () => {
            // if(userCookieData && parentUserCookieData && parentUserOrgCookieData && isMounted){
            //     try {
            //         await axios.post(api_routes.profile.checkLogin, {});
            //         setUser(userCookieData)
            //         setParentUser(parentUserCookieData)
            //         setParentUserOrg(parentUserOrgCookieData)
            //     } catch (error) {
            //         removeUser();
            //     }
            // }
            // else{
            //     removeUser();
            // }
            // isMounted && setLoading(false)

            if (token) {
                setCookie(process.env.REACT_APP_CURRENT_ENV === "production" ? "portalTokenKey" : "stagingAuthToken", token)
            }

            try {
                const response = await axios.post<{ data: { finalData: UserType } }>(api_routes.profile.checkLogin, {});
                if (userCookieData && isMounted) {
                    const response = await axios.get(api_routes.profile.myProfile);
                    const profile = response.data.data as MyProfileType;
                    setUser({
                        token: userCookieData.token,
                        user: {
                            ...userCookieData.user,
                            profile: {
                                ...userCookieData.user.profile,
                                profileImage: profile.profileImage
                            }
                        }
                    })
                    if (parentUserCookieData && isMounted) {
                        setParentUser(parentUserCookieData)
                    }
                    if (parentUserOrgCookieData && isMounted) {
                        setParentUserOrg(parentUserOrgCookieData)
                    }
                } else {
                    setUser(response.data.data.finalData)
                    setParentUser(response.data.data.finalData)
                    setParentUserOrg({
                        oId: response.data.data.finalData.user.profile.oid,
                        oname: response.data.data.finalData.user.profile.oname
                    })
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    if (error.response?.status === 403) {
                        setIsForbidden(true)
                    } else {
                        setIsForbidden(false)
                        removeUser();
                    }
                } else {
                    setIsForbidden(false)
                    removeUser();
                }
            } finally {
                isMounted && setLoading(false)
            }
        }

        // (userCookieData && parentUserCookieData && parentUserOrgCookieData && isMounted) ? checkUserAuthenticated() : setLoading(false)
        (isMounted) ? checkUserAuthenticated() : setLoading(false)

        return () => { isMounted = false; checkUserAuthenticated() };

    }, [axios, parentUserCookieData, parentUserOrgCookieData, removeUser, setParentUser, setParentUserOrg, setUser, token, userCookieData])

    if (isForbidden) {
        return (<InsufficientPermissionPage />)
    }

    return (
        <>
            {
                loading ? <PageLoader /> : <Suspense fallback={<PageLoader />}>
                    <Outlet />
                </Suspense>
            }
        </>
    )
}

export default PersistUserLoginLayout